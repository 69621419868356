import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/About/TextPage'
import Navigation from 'components/layout/Navigation/index'

const TextPage: React.FC<PageProps> = ({ data, pageContext }) => {
  const PAGE = data?.wpPage
  const PAGE_SEO = data?.wpPage?.seo

  const breadcrumbs = {
    mobile: [
      {
        label: 'Home',
        link: '/',
      },
      {
        label: PAGE?.title,
      },
    ],
    desktop: [
      {
        label: 'Home',
        link: '/',
      },
      {
        label: PAGE?.title,
      },
    ],
  }

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.podstronaTekstowa?.heroImg?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.podstronaTekstowa?.heroImg?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        headingMobile={PAGE?.title}
        headingDesktop={PAGE?.title}
        breadcrumbs={breadcrumbs}
      />
      <About
        content={PAGE?.content}
        isWhyGatigoPage={pageContext.slug === 'dlaczego-gatigo'}
      />
    </Layout>
  )
}

export default TextPage

export const query = graphql`
  query TextPage($slug: String) {
    wpPage(slug: { eq: $slug }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      title
      content
      podstronaTekstowa {
        heroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
